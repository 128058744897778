/* ------ Découvrir nos activités ------ */

.content__discover__content {
  --margin-left: 170px;
  --offset: 50px;
  margin-left: var(--margin-left);
  @media (max-width: 1400px) {
    --margin-left: 100px;
  }
  @media (max-width: 768px) {
    margin-left: 15px;
  }
  max-width: var(--body-width);
  padding: 0 50px;
  background: transparent;
}

.content__discover__item {
  background: linear-gradient(#63b7e7, #00aeb9);
  border: 4px solid white;

  border-radius: 10px;
  padding-left: 27px;
  padding-right: 27px;
  padding-top: 16px;
  padding-bottom: 39px;
  position: relative;

  &:before {
    content: url("../images/bubble.svg");
    position: absolute;
    width: 200px;
    height: 190px;
    z-index: -1;
    transition: 0.5s;
  }

  &:nth-of-type(3n + 1) {
    &:before {
      top: -50px;
      left: calc(-1 * var(--offset));
    }
  }

  &:nth-of-type(3n + 2):before {
    bottom: -80px;
    left: -15px;
  }
  &:nth-of-type(3n + 3):before {
    top: -33px;
    right: -44px;
  }

  &__title {
    color: var(--white);
    font-family: var(--font-alt);
    font-size: 26px;
    font-weight: 500;
    max-width: 265px;
  }
  &__subtitle {
    font-size: 15px;
    color: var(--grey) !important;
    max-width: 235px;
    padding-top: 15px;
    display: block;
  }
  .discover__arrow {
    position: absolute;
    bottom: 10px;
    right: 15px;
    &:before {
      --arrow-line-width: 0px;
      content: "";
      position: absolute;
      height: 1px;
      width: var(--arrow-line-width);
      left: calc(-1 * var(--arrow-line-width));
      background: var(--white);
      bottom: calc(50% - 1px);
      right: 0;
      transition: 0.5s;
    }
  }
  &:hover {
    &:before {
      transform: scale(1.05) translateX(10px) translateY(10px);
    }
    .discover__arrow:before {
      --arrow-line-width: 50px;
    }
  }
}

.discover__wrapper {
  display: flex;
  justify-content: space-between;
  max-width: var(--content-width);

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }

  > a {
    --max-width: 320px;
    width: 100%;
    max-width: var(--max-width);
    width: var(--width);

    @media (max-width: 768px) {
      --max-width: 400px;
      width: 100%;
    }

    @media (min-width: 900px) {
      margin-right: 50px;
    }

    @media (max-width: 900px) {
      margin-bottom: 90px;
    }
  }
}

.discover__img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  transition: 0.5s;
  z-index: -1;
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transform: translateY(-100%);
  }
}
