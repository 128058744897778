.section {
  margin: 0 auto;
  margin-bottom: 150px;

  @media (max-width: 768px) {
    margin-bottom: 60px;
    &:first-of-type {
      body:not(.is-home) & .cover-wrapper:after {
        background: var(--theme);
      }
      body.is-home {
        margin-top: 100px;
      }
    }
  }

  &__header {
    position: relative;
    display: block;
    max-width: var(--body-width);
    margin: 0 auto;
    img {
      display: block;
      max-width: 50vw;

      @media (min-width: 768px) {
        mask-image: url(../images/section-title-mask.svg);
        -webkit-mask-image: url(../images/section-title-mask.svg);
      }

      @media (max-width: 768px) {
        border-top-right-radius: 0;
        width: 100vw;
        height: auto;
        max-width: none;
      }
    }
  }

  &__title {
    --color: var(--white);

    @media (max-width: 1340px) {
      max-width: none;
    }

    font-size: 20px;
    text-align: right;
    position: absolute;
    bottom: 20px;
    right: 85%;
    margin: 0;
    top: auto;
    padding-bottom: 10px;
    color: var(--color);
    border-bottom: 4px solid var(--color);
    z-index: 1;

    @media (max-width: 768px) {
      left: var(--gutter);
      right: auto;
      text-align: center;
      width: 100%;
      bottom: var(--gutter);
      font-size: 16px;
      display: block;
      margin: auto;
    }

    &:not(&--no-cover) {
      max-width: 170px;
      @media (max-width: 1400px) {
        // Sur les écrans de laptop, il convient de réduire la largeur du titre pour
        // être sûr qu'il ne sorte pas de l'image.
        max-width: 140px;
      }
    }

    &--nocover {
      --color: var(--black);

      .theme-ve & {
        color: var(--green);
        border-bottom: 4px solid var(--green);
      }
      .theme-tu & {
        color: var(--turquoise);
        border-bottom: 4px solid var(--turquoise);
      }
      .theme-bl & {
        color: var(--blue);
        border-bottom: 4px solid var(--blue);
      }
    }
    &__spacer {
      display: block;
      width: 754px;
    }
  }

  &__content {
    position: relative;
    padding: 2em;
    max-width: var(--content-width);
    margin: 0 auto;
    border-radius: 10px;
    margin-top: -50px;
    margin-bottom: 50px;
    position: relative;
    padding: 10px 15px;
    @media (max-width: 1340px) {
      margin-top: 0;
    }

    @media (max-width: 768px) {
      body.is-home & {
        margin-top: 50px;
      }
    }

    > div {
      // Les blocks composant une section: correspond aux items d'un champ streamfield
      margin-bottom: 50px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    p {
      margin: 0;
      line-height: 1.5em;
      margin-bottom: 0.75em;
      letter-spacing: 0.56px;

      @media (max-width: 600px) {
        margin-top: 30px;
      }
      &:last-child {
        margin-bottom: 0;
      }

      &:empty {
        margin: 0;
      }
    }
  }
}

.cover-wrapper {
  position: relative;
  @media (min-width: 768px) {
    img {
      filter: brightness(70%);
    }
  }
  @media (max-width: 1340px) and (min-width: 768px) {
    max-height: calc((50vw * 245 / 754) - 4vw);
    overflow: hidden;
  }
  @media (max-width: 768px) {
    &:before {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      top: 1px; // Evite un glitch en haut de l'image
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
    }
    img {
      border-top: 1px solid white; // Evite un glitch en haut de l'image
    }
    &:after {
      content: "";
      top: -1px;
      left: -1px;
      right: -1px;
      height: calc(100vw * 67.6 / 414);
      position: absolute;
      background-color: white;
      mask-image: url(../images/fill-cover.svg) center center / cover;
      -webkit-mask-image: url(../images/fill-cover.svg);
      -webkit-mask-size: cover;
      -webkit-mask-origin: center center;
      -webkit-mask-repeat: no-repeat;
    }
  }
}

.fill-top {
  width: 14.5px;
  height: 14.5px;
  bottom: 0;
  bottom: -4px;
  left: 246px;
  position: absolute;
  @media (max-width: 1340px) {
    display: none;
  }
}

.fill-bottom {
  left: -11px;
  top: 40px;
  width: 14.5px;
  height: 14.5px;
  position: absolute;
  @media (max-width: 1340px) {
    display: none;
  }
}
