.template-workpage {
  .hero {
    background: linear-gradient(#63b7e7, #00aeb9);

    .content__title {
      color: white;
    }
    .content__subtitle {
      color: white;
      font-size: 23px;
      font-weight: 500;
    }
  }

  .content {
    img {
      width: 100%;
      height: auto;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    iframe {
      width: 100%;
      display: block;
    }
    &__description {
      p {
        padding-top: 20px;
        padding-bottom: 20px;
      }
      iframe {
        display: block;
        width: 90%;
        height: auto;
        margin: 10px auto;
      }
    }
    &__contact {
      margin-top: 100px;
      display: none;
    }

    &__map {
      display: none;
    }

    &__discover {
      display: none;
    }

    &__photos {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

