.hero {
  position: relative;
  .theme-bl & {
    background-color: var(--blue);
  }
  .theme-ve & {
    background-color: var(--green);
  }
  .theme-tu & {
    background-color: var(--turquoise);
  }
  &:after {
    // Problème de ligne colorée en bas du hero.
    // On simule un background très large et peu haut qui recouvre
    // ce glitch.
    content: "";
    background: var(--white);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    body.no-first-image & {
      background: white;
      height: 10px;
    }
    body.is-home & {
      display: none;
    }
    @media (max-width: 768px) {
      // À ce stade...c'est vraiment du bricolage.
      // Empêche l'affichage de la ligne blanche en mobile sur le premier item de la liste.
      body.is-home & {
        --theme: white;
      }
      background: var(--theme);
      bottom: -3px;
      z-index: 1;
    }
  }
}

.fill-hero {
  transform: rotate(180deg);
  width: 100%;
  @media (max-width: 1000px) {
    margin-top: 100px;
  }
  display: block;
  body:not(.no-first-image) & {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .theme-bl & {
    fill: var(--blue);
  }

  .theme-ve & {
    fill: var(--green);
  }

  .theme-tu & {
    fill: var(--turquoise);
  }

  @media (max-width: 768px) {
    margin-top: -30px;
  }
}

.header {
  font-family: var(--font-alt);
  --pb: 250px;
  padding-bottom: var(--pb);
  body:not(.is-home) & {
    --pb: 0;
  }

  @media (max-width: 768px) {
    --pb: 150px;
  }
}

.menu {
  justify-content: flex-start;
  align-items: center;
  display: flex;
  padding-top: 35px;
  max-width: var(--body-width);
  margin: 0 auto;

  &__logo {
    margin-right: 5vw;
    img {
      width: 75px;
      height: auto;
      margin: 0 25px;
    }

    &__mobile {
      @media (min-width: 768px) {
        display: none;
      }
      position: absolute;
      z-index: 1;
      left: 20px;
      top: 20px;
      img {
        width: 75px;
        height: auto;
      }
    }
  }

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(15, 121, 128, 0.8);
    backdrop-filter: blur(15px);
    opacity: 0;
    visibility: hidden;
    z-index: 100;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    transition: 500ms;
    // &:after {
    //   FIXME: Effet de flou sur le fond de page
    //   content: "";
    //   background: white;
    //   background: var(--turquoise);
    //   position: absolute;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   top: 0;
    //   opacity: .5;
    //   filter: blur(5px);
    // }
  }
  &__item {
    color: var(--black);
    padding-bottom: 1em;
    margin: 0 2.5vw;

    .header & {
      &:hover {
        color: var(--turquoise);

        .theme-bl & {
          color: var(--white);
        }
        .theme-ve & {
          color: var(--white);
        }
        .theme-tu & {
          color: var(--white);
        }
      }
    }

    @media (max-width: 768px) {
      .hero & {
        color: var(--white);
        font-size: 28px;
        font-weight: bold;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        letter-spacing: 1px;
        padding: 1em 0;
      }
    }

    &--active {
      color: white;
      @media (min-width: 768px) {
        border-bottom: 3px solid var(--white);
      }
    }
  }
}

.burger-menu {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 25px;
  right: 25px;
  height: 30px;
  z-index: 1000;
  background: var(--turquoise);
  width: 52px;
  height: 52px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;

  path {
    fill: var(--white);
  }

  @media (min-width: 768px) {
    display: none;
  }
}

.mobile-menu-opened {
  .burger-menu {
    .line-1 {
      transform: rotate(45deg) translate(3px, 4px);
    }
    .line-2 {
      display: none;
    }
    .line-3 {
      transform: rotate(-45deg) translate(4px, -3px);
    }
    path {
      fill: var(--turquoise);
    }
    background: white;
  }

  .menu {
    opacity: 1;
    visibility: visible;
  }
}

.burger-menu .line {
  height: 10px;
  transition: 500ms;
}

.menu-line-short {
  @media (max-width: 768px) {
    width: 30px;
    height: 5px;
    background-color: var(--white);
  }
  &:last-of-type {
    display: none;
  }
}

.menu img {
  @media (max-width: 768px) {
    display: none;
  }
}
