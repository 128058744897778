.drop {
  --rotate-value: 1deg;
  --scroll-value: 1;
  transform-origin: center;
  // transform: rotate(calc(var(--rotate-value) * 1deg))
  &:after {
    content: "";
    display: block;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
    height: 220px;
    width: 150px;
    transform-origin: center 0;
    transform: rotate(var(--rotate-value))
      translateY(calc(var(--scroll-value) * -1px))
      translateX(calc(var(--scroll-value) * -10px));
    transition: 0.1s;
    @media (max-width: 768px) {
      width: 90px;
      height: 130px;
    }
  }

  &--blue {
    &:after {
      --rotate-value: -60deg;
      background-image: url("../images/drop-blue.png");
      top: 70px;
      left: 0;
      @media (max-width: 768px) {
        left: -60px;
      }
    }
  }

  &--turquoise {
    &:after {
      --rotate-value: -130deg;
      background-image: url("../images/drop-turquoise.png");
      top: 0;
      right: 35%;
      @media (max-width: 768px) {
        right: 20vw;
        top: 20px;
      }
    }
  }

  &--green {
    &:after {
      --rotate-value: 120deg;
      background-image: url("../images/drop-green.png");
      top: 250px;
      right: 21%;
      @media (max-width: 768px) {
        top: 200px;
        right: 0;
      }
    }
  }
  body:not(.is-home) & {
    display: none;
  }
}

.content {
  &__title {
    font-family: var(--font-alt);
    @media (min-width: 768px) {
      padding: 0 var(--padding);
    }
    position: relative;
    margin: 0;
    font-size: 60px;
    @media (max-width: 768px) {
      font-size: 50px;
    }
    font-weight: bold;

    body:not(.is-home) & {
      padding-top: 100px;
    }

    .theme-bl & {
      color: var(--white);
    }
    .theme-ve & {
      color: var(--white);
    }
    .theme-tu & {
      color: var(--white);
    }

    &__last {
      display: block;

      .theme-bl & {
        color: var(--dark-blue);
      }

      .theme-ve & {
        color: var(--olive-green);
      }

      .theme-tu & {
        color: var(--dark-blue);
      }
    }

    &__wrapper {
      --padding: calc((var(--title-width) - var(--content-width)) / 2);
      max-width: var(--title-width);
      margin: 0 auto;
      position: relative;
      display: flex;
      flex-direction: column;

      @media (max-width: 768px) {
        padding: 20px;
      }
    }
  }

  &__subtitle {
    display: block;
    line-height: 1.522;
    @media (min-width: 768px) {
      padding-left: var(--padding);
    }
    .theme-bl & {
      color: white;
      font-size: 23px;
      font-weight: 500;
    }

    .theme-ve & {
      color: white;
      font-size: 23px;
      font-weight: 500;
    }

    .theme-tu & {
      color: white;
      font-size: 23px;
      font-weight: 500;
    }
  }

  &__subheader {
    @media (min-width: 768px) {
      padding-left: var(--padding);
    }
    display: grid;
    grid-template-columns: repeat(var(--items-count), 1fr);
    grid-gap: 10px;
    margin-right: auto;
    margin-bottom: 50px;
    margin-top: 50px;
    @media (max-width: 768px) {
      display: none;
    }

    a {
      color: var(--black);
      &:hover {
        color: var(--turquoise);

        .theme-bl & {
          color: var(--white);
        }
        .theme-ve & {
          color: var(--white);
        }
        .theme-tu & {
          color: var(--white);
        }
      }
    }

    &__logo {
      display: none;
      visibility: hidden;
      transform: translateY(-100px);
      transition: 0.5s;
    }

    &--scrolled {
      display: flex;
      flex-direction: row;
      // justify-content: flex-start;
      position: fixed;
      z-index: 10;
      background: white;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      @media (max-width: 768px) {
        display: none;
      }
      height: 60px;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-top: 0;
      a {
        margin-right: 2em;
        &:hover {
          color: var(--theme) !important;
        }
      }
    }
    &--scrolled &__logo {
      visibility: visible;
      transform: translateY(0);
      transition: 0;
      &,
      img,
      picture {
        display: block;
        height: 100%;
        width: auto;
      }
    }
  }

  /* &__subheader a {
    justify-content: space-evenly;
    margin-right: 35px;
    color: var(--black);
  } */

  &__subtitle {
    font-size: 30px;
    max-width: 800px;

    &--big {
      max-width: var(--body-width);

      margin: 0 auto;
      margin-bottom: 205px;
      font-size: 40px;
      text-align: left;
      font-weight: 400;

      @media (max-width: 1540px) {
        // La media query correspond ici à --body-width + 100px
        --offset: 100px;
        padding-left: var(--offset);
      }

      @media (max-width: 768px) {
        margin-bottom: 110px;
      }

      @media (max-width: 600px) {
        --offset: 40px;
      }
      strong {
        &:before {
          // Pour forcer le passage à la ligne du strong, tout en évitant de le
          // mettre en display: block; Cela aurait pour conséquence de
          // dessiner une bordure sur toute la largeur du bloc;
          content: "";
          display: block;
        }
        color: var(--black);
        border-bottom: 5px solid var(--black);
      }
    }
  }

  &__work {
    margin-top: 150px;
    h2 {
      max-width: 350px;
      font-size: 40px;
      text-align: left;
      margin-left: 170px;
      font-weight: 400;
      margin-bottom: 90px;
      strong {
        border-bottom: 5px solid var(--black);
      }
    }
  }
}
