.block-services {
  li {
    list-style: none;
  }
  ul {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-evenly;
    @media (max-width: 768px) {
      flex-direction: column;
      margin-left: 30%;
    }

    @media (max-width: 600px) {
      margin-left: 10%;
    }
  }
}

.service {
  display: flex;
  flex-direction: column;
  max-width: 268px;
  margin-right: 30px;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
}

.service_title_wrapper {
  position: relative;
}

.service {
  img {
    max-width: 100%;
    max-height: 208px;
    display: block;
  }
  strong {
    font-family: var(--font-alt);
    font-size: 20px;
    font-weight: 500;
    font-style: italic;
    color: var(--white);
    margin-top: 12px;
  }

  p {
    color: var(--black);
    font-size: 12px;
    margin-top: 20px;
    font-weight: 400;
    line-height: 18px;
  }
  &_picto_and_img {
    position: absolute;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background: rgba(0, 174, 185, 0.3);

      .theme-bl & {
        background: rgba(99, 183, 231, 0.3);
      }

      .theme-ve & {
        background: rgba(149, 194, 30, 0.3);
      }

      .theme-tu & {
        background: rgba(0, 174, 185, 0.3);
      }
    }
  }
}
