.block-work {
  ul,
  li:not(.tags__item) {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  ul:not(.tags__list) {
    padding-top: 2em;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-gap: 20px;
    @media (max-width: 1000px) {
      grid-template-columns: repeat(1, 100%);
    }
  }

  &__item {
    $self: &;
    padding-bottom: 2em;
    display: flex;
    flex-direction: column;

    &,
    &:visited {
      color: var(--color);
    }

    img {
      // Image de couverture
      display: block;
      width: 100%;
      height: auto;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    strong {
      // Le titre
      display: block;
      margin-top: 0.5em;
      font-size: 1em;
      font-family: "Mulish";
      line-height: 1.5em;
      // letter-spacing: 2.4px;
      // border-left: 5px solid var(--theme);
      // padding-left: 0.5em;
      color: var(--theme);
      transition: 0.5s;
    }
    p {
      // L'introduction
      padding-top: 1em;
      @media (max-width: 768px) {
        padding: 0;
      }
    }
  }
}
.tags {
  &__list {
    display: flex;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    margin-top: auto;
    margin-bottom: 30px;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__item {
    background: transparent;
    border: none;
    line-height: 1.5em;
    margin-right: 10px;
    color: var(--theme);
    border: 1px solid rgb(0, 175, 184, 0.2);
    background: rgb(0, 175, 184, 0.1);
    border-radius: 5px;
    padding: 5px;
    list-style: none;
    font-size: 10px;

    @media (max-width: 768px) {
      margin: 5px 0;
      display: inline-block;
    }
  }
}
