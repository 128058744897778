.catchphrase {
  --padding-left: 200px;
  display: flex;
  flex-direction: column;
  padding-left: var(--padding-left);
  @media (max-width: 768px) {
    padding: 0;
  }
  max-width: var(--body-width);
  margin: 0 auto;
}

.catchphrase p {
  max-width: 970px;
  line-height: 1.875em;
  margin-top: -20px;
  margin-bottom: 30px;
}

.catchphrase__title {
  display: flex;
  align-items: center;
  h2 {
    color: var(--turquoise);
    letter-spacing: 2.4px;
    border-bottom: 4px solid var(--turquoise);
    padding-bottom: 10px;

    .theme-bl & {
      color: var(--blue);
      border-bottom: 4px solid var(--blue);
    }

    .theme-ve & {
      color: var(--green);
      border-bottom: 4px solid var(--green);
    }

    .theme-tu & {
      color: var(--turquoise);
      border-bottom: 4px solid var(--turquoise);
    }
  }
  .interrogation-icon {
    margin-right: 38px;
    @media (max-width: 768px) {
      margin-left: 20px;
    }
  }
  .interrogation-bullet {
    .theme-bl & {
      fill: var(--blue);
    }

    .theme-ve & {
      fill: var(--green);
    }

    .theme-tu & {
      fill: var(--turquoise);
    }
  }
}

.catchphrase__content {
  margin-top: auto;
  margin-left: 100px;

  @media (max-width: 600px) {
    margin-left: 80px;
  }
}

.catchphrase__author {
  font-size: 10px;
  display: flex;
  align-items: center;
}

.catchphrase__author__name {
  text-transform: uppercase;
  color: var(--turquoise);

  .theme-bl & {
    color: var(--blue);
  }

  .theme-ve & {
    color: var(--green);
  }

  .theme-tu & {
    color: var(--turquoise);
  }

  &:after {
    content: "";
    height: 10px;
    width: 3px;
    background: var(--turquoise);
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;

    .theme-bl & {
      background: var(--blue);
    }

    .theme-ve & {
      background: var(--green);
    }

    .theme-tu & {
      background: var(--turquoise);
    }
  }
}
