.block-flowchart {
  font-size: 14px;
  li {
    list-style: none;
    max-width: 150px;
    border: 2px solid var(--green);
    border-radius: 10px;

    padding: 20px 15px 10px 15px;
    position: relative;

    text-align: center;

    @media (max-width: 768px) {
      max-width: 235px;
    }
  }
  ul {
    display: flex;
    list-style: none;
    margin-left: 50px;
    li {
      margin: 0 7.5px;
    }

    @media (max-width: 768px) {
      margin: 0;
      padding: 0;
      align-items: center;

      li {
        margin: 7.5px 0;
      }

      flex-direction: column;
    }
  }
}

.flowchart {
  &:before {
    --height: 20px;
    --width: 22px;
    --right-padding: 15px;

    content: "";

    background: url("../../images/chart-dot.svg") white;
    background-size: var(--width) var(--height);
    background-position: center left;
    background-repeat: no-repeat;

    display: block;
    width: calc(var(--width) + var(--right-padding));
    height: var(--height);

    position: absolute;
    left: 0;
    right: 0;
    top: -2px;
    bottom: auto;
    margin: auto;
  }
  @media (max-width: 768px) {
    &:not(first-of-type):before {
      display: none;
    }
  }

  &:after {
    --height: 46px;
    --width: 31px;
    --top-padding: 15px;

    content: "";
    background: url("../../images/chart-arrow-dot.svg") white;
    background-size: var(--width) var(--height);
    background-position: bottom center;
    background-repeat: no-repeat;

    display: block;
    width: var(--width);
    height: calc(var(--height) + var(--top-padding));

    position: absolute;
    top: 50px;
    right: -31px;
    bottom: auto;
    left: auto;

    z-index: 1;

    @media (max-width: 768px) {
      bottom: 0;
      top: auto;
      left: 0;
      right: 0;
      transform: rotate(90deg);
      transform-origin: 0 bottom;
      margin: auto;
    }
  }
}
.block-flowchart li:last-of-type .flowchart:after {
  display: none;
}
