.highlight {
  &-title svg {
    stroke: var(--black);
    #Fill-1 {
      stroke: transparent;
      fill: var(--turquoise);
      .theme-ve & {
        fill: var(--green);
      }
      .theme-bl & {
        fill: var(--blue);
      }
      .theme-tu & {
        fill: var(--turquoise);
      }
    }
  }
  &-text p {
    font-size: 12px;
    line-height: 1.5;
    display: flex;
    align-items: center;
    .theme-ve & {
      color: var(--olive-green);
    }
    .theme-bl & {
      color: var(--dark-blue);
    }
    .theme-tu & {
      color: var(--turquoise);
    }

    @media (max-width: 768px) {
      max-width: 230px;
    }
  }

  &-bullet {
    width: 17.6px;
    height: 18.7px;
    min-width: 17.6px;
    min-height: 18.7px;
    margin-right: 20px;
  }

  &-bullet path {
    .theme-ve & {
      fill: var(--green);
    }
    .theme-bl & {
      fill: var(--blue);
    }
    .theme-tu & {
      fill: var(--turquoise);
    }
  }
  &-title {
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      margin-left: 42px;
    }
    h4 {
      font-size: 16px;
      padding-bottom: 10px;
      margin-left: 22px;
      .theme-bl & {
        color: var(--blue);
        border-bottom: 4px solid var(--blue);
      }
      .theme-ve & {
        color: var(--green);
        border-bottom: 4px solid var(--green);
      }
      .theme-tu & {
        color: var(--turquoise);
        border-bottom: 4px solid var(--turquoise);
      }
    }
  }
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 250px);
  grid-gap: 25px 150px;
  margin-left: 140px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
  }
}
