.footer {
  position: relative;
  line-height: 1.25em;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
  }

  &,
  a {
    color: var(--turquoise);
  }
  a:hover {
    color: var(--dark-blue);
  }
  &__contact {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 95px;
    font-family: var(--font-alt);
    font-weight: bold;

    @media (max-width: 768px) {
      order: 3;
      padding-top: 45px;
    }

    &,
    p {
      font-size: 25px;
    }
  }

  &__logo {
    position: absolute;
    top: 120px;
    left: 50px;

    @media (max-width: 768px) {
      order: 1;
      position: static;
      align-self: center;
    }

    &,
    img {
      width: 92px;
      height: 92px;
    }
  }

  &__menu {
    display: flex;
    padding: 15px;
    justify-content: center;
    font-size: 12px;
    font-family: var(--font-alt);

    @media (max-width: 768px) {
      order: 2;
      padding-top: 50px;
    }

    @media (max-width: 600px) {
      flex-direction: column;
      margin-left: 35px;
    }

    &__block {
      margin: 30px;
      &__title {
        margin: 0;
        font-weight: bold;
        text-transform: uppercase;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
      &__sections {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        &__item {
          padding: 5px;
        }
      }
    }
  }

  &__links {
    padding-top: 70px;
    padding-bottom: 30px;
    text-align: center;
    justify-content: center;

    @media (max-width: 768px) {
      position: static;
      order: 4;
    }

    &,
    a {
      padding: 20px;
      font-size: 12px;
    }
  }

  &__contact__wrapper {
    display: flex;
    padding-left: 40px;
    padding-bottom: 70px;

    &,
    a {
      margin-right: 20px;
    }
  }
}

.instagram {
  display: block;
  background: url('../images/instagram.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 37px;
  height: 37px;
}

.mail {
  display: block;
  background: url('../images/mail.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 37px;
  height: 37px;
}

.linkedin {
  display: block;
  background: url('../images/linkedin.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 37px;
  height: 37px;
}
