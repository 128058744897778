/* ------ Carte ------ */
.content__map {
  position: relative;
  width: 100vw;
  margin: 0 auto;
  margin-bottom: 100px;

  &__images {
    max-width: 1500px;
    img:first-of-type {
      // La carte
      width: 100%;
      height: auto;
    }
  }
  &__arrow-red {
    position: absolute;
    left: 731px;
    top: 145px;
  }
}
