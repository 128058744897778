.content__contact {
  margin-top: 190px;

  @media (max-width: 768px) {
    margin-top: 100px;
  }
}

.contact-wrapper {
  display: flex;
  padding-left: 80px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding-left: 40px;
  }
}

.email {
  width: 330px;
  height: 55px;
  background: var(--turquoise);
  border-radius: 10px;
  margin-right: 270px;

  &:hover {
    background: var(--dark-blue);
  }

  @media (max-width: 768px) {
    margin-right: 0;
  }
}

.email a {
  color: var(--white);
  font-size: 20px;
  font-family: var(--font-alt);
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-left: 25px;
  padding: 10px;

  &:before {
    content: "";
    content: url(../images/atsign.svg);
    background-size: cover;
    display: block;
    width: 39px;
    height: 39px;
    margin-right: 25px;
  }
}

/* .email a span {
    color: var(--white);
    -webkit-text-fill-color: var(--turquoise);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--turquoise);
} */

.contact-phone {
  line-height: 1.5;
  padding-top: 10px;
}
