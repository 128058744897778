.bullet {
  @media (max-width: 768px) {
    padding-top: 60px;
  }
}

.block-bullets {
  .bullet {
    margin-top: 45px;
  }
  > ul {
    list-style-type: none;
    line-height: 1.5em;
    @media (max-width: 768px) {
      padding: 0;
    }
    li strong {
      text-transform: uppercase;
      font-weight: normal;
    }
  }

  path {
    --fill: var(--turquoise);
    fill: var(--fill);
    .theme-tu & {
      --fill: var(--turquoise);
    }
    .theme-ve & {
      --fill: var(--green);
    }
    .theme-bl & {
      --fill: var(--blue);
    }
  }

  p {
    line-height: 22px;

    @media (max-width: 768px) {
      margin-left: 0;
    }
  }
}
