.block-pictos {
  li {
    list-style: none;
  }
  ul {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-evenly;
    @media (max-width: 600px) {
      display: grid;
      grid-template-columns: repeat(2, 150px);
      grid-gap: 50px;
    }
  }
}

.picto {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 130px;
  align-items: center;
  align-content: center;
  .theme-ve &,
  .theme-bl &,
  .theme-tu & {
    text-align: left;
  }
  strong {
    margin-top: 20px;
    font-size: 15px;
    font-weight: 900;
  }

  span {
    .theme-ve &,
    .theme-bl &,
    .theme-tu & {
      font-size: 12px;
      color: var(--olive-green);
    }
  }

  img {
    align-self: center;
  }

  svg {
    stroke: var(--black);
    #fill-1,
    #Fill-1 {
      stroke: transparent;
      fill: var(--turquoise);
      .theme-ve & {
        fill: var(--green);
      }
      .theme-bl & {
        fill: var(--blue);
      }
      .theme-tu & {
        fill: var(--turquoise);
      }
    }
  }
}
