.content__work {
  position: relative;
}

.slider {
  --slider-width: 100vw;
  --counter: 0;
  --offset: calc(-1 * var(--counter) * var(--slider-width));

  width: var(--slider-width);
  overflow-x: hidden;
  text-align: center;
  background: linear-gradient(#63b7e7, #00aeb9);

  @media (max-width: 1000px) {
    padding-bottom: 80px;
  }

  &__inner {
    display: flex;
    transition: 0.8s;
    transform: translateX(var(--offset));
  }

  &__item {
    min-width: var(--slider-width);
    width: var(--slider-width);
    @media (max-width: 1000px) {
      padding: var(--gutter);
    }

    &__cover {
      @media (min-width: 1000px) {
        width: 462px;
        height: 316px;
        order: 1;
      }
      @media (max-width: 1000px) {
        width: 90%;
        margin: 0 auto;
      }
    }

    &__inner {
      position: relative;
      max-width: var(--content-width);
      margin: 0 auto;
      @media (max-width: 1000px) {
        display: flex;
        flex-direction: column;
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 20px;
    border-top-right-radius: 100px;

    @media (min-width: 1000px) {
      max-width: 500px;
      height: auto;
    }
  }

  &__control {
    @media (min-width: 1000px) {
      position: absolute;
      z-index: 1;
      width: 100%;
      max-width: var(--title-width);
      left: 0;
      right: 0;
      height: 0;
      top: 0;
      margin: 0 auto;
    }
  }
  &__next {
    cursor: pointer;
    position: absolute;
    right: var(--gutter);
    top: 395px;
    cursor: pointer;

    @media (max-width: 1000px) {
      top: auto;
      border: 0;
      right: 25px;
      bottom: 30px;
    }
  }
  &__dot {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;

    .dot {
      margin-right: 15px;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background: var(--turquoise);
      border: 1px solid white;
      cursor: pointer;

      &.active {
        background: white;
      }
    }

    @media (max-width: 1000px) {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  h2 {
    max-width: 280px;
    font-size: 40px;
    text-align: left;
    margin-left: 170px;
    font-weight: 400;
    color: var(--white);

    @media (max-width: 1000px) {
      margin-left: 40px;
    }

    strong {
      border-bottom: 5px solid var(--white);
    }
  }

  &__counter {
    font-family: var(--font-alt);
    font-size: 30px;
    color: var(--white);
    font-weight: 500;
    margin-left: 1000px;

    @media (max-width: 1000px) {
      margin-left: 270px;
    }
  }

  &__item {
    position: relative;
    padding-bottom: 180px;

    @media (max-width: 1000px) {
      padding-bottom: 40px;
    }

    &__title {
      max-width: 300px;
      font-family: var(--font-alt);
      color: var(--white);
      position: absolute;
      text-align: left;
      top: 40px;
      left: 60%;
      right: 0;
      font-size: 35px;

      &:hover {
        color: var(--dark-blue);
      }

      @media (max-width: 1000px) {
        --image-height: calc((90vw * 776 / 688) - (2 * var(--gutter)));
        left: calc(10% + 25px);
        top: 0;
        bottom: calc(105% - var(--image-height));
        display: flex;
        align-items: flex-end;
        font-size: 30px;
        padding-bottom: calc(2 * var(--gutter));
      }
    }

    &__date {
      position: absolute;
      top: 9%;
      font-size: 10px;
      color: var(--turquoise);
      @media (max-width: 1000px) {
        display: none;
      }
    }

    &__intro {
      max-width: 350px;
      text-align: left;
      background: var(--white);
      border-radius: 10px;
      padding-top: 40px;
      padding-bottom: 40px;
      padding-left: 15px;
      padding-right: 15px;
      font-size: 15px;
      line-height: 1.9;
      position: absolute;
      top: 200px;
      left: 380px;
      &,
      a {
        color: black;
      }

      @media (max-width: 1000px) {
        width: 345px;
        max-width: 100%;
        position: static;
        order: 2;
        margin: -25px auto 0 auto;
      }
    }
  }

  &__intro__arrow {
    position: absolute;
    bottom: 20px;
    right: 20px;

    &:before {
      --arrow-line-width: 0px;
      content: "";
      position: absolute;
      height: 1px;
      width: var(--arrow-line-width);
      left: calc(-1 * var(--arrow-line-width));
      background: var(--turquoise);
      bottom: calc(50% - 1px);
      right: 0;
      transition: 0.5s;
    }

    @media (max-width: 1000px) {
      display: none;
    }
  }
  &__item__intro:hover &__intro__arrow:before {
    --arrow-line-width: 50px;
  }

  &__prev {
    cursor: pointer;
    position: absolute;
    left: var(--gutter);
    top: 395px;
    cursor: pointer;

    @media (max-width: 1000px) {
      top: auto;
      left: 25px;
      bottom: 30px;
    }
  }

  .slide {
    width: 100%;
    flex-shrink: 0;
    height: 100%;
  }
}

.slider_tags {
  position: absolute;
  left: 0;
  right: calc(100% - 380px);
  // word-break: break-all;

  &_list {
    display: flex;
    margin-top: 20px;
    list-style: none;
    padding: 0;

    @media (max-width: 1000px) {
      display: none;
    }
  }

  &_item {
    border: none;
    color: var(--white);
    margin-right: 15px;
    text-decoration: underline;
    font-size: 12px;
    text-align: left;
  }
}
