.block-intro {
  --intro-offset: 50px;
  max-width: 570px;
  font-size: 30px;
  font-weight: 900;
  line-height: 45px;
  font-family: var(--font-alt);
  font-style: italic;
  color: var(--theme);
  display: flex;
  position: relative;
  margin-left: var(--intro-offset);
  width: calc(100% - var(--intro-offset));

  &:before {
    display: block;
    content: "";
    background-color: var(--theme);
    mask-image: url(../../images/intro-drops.svg);
    -webkit-mask-image: url(../../images/intro-drops.svg);
    width: 36px;
    height: 29px;
    position: absolute;
    top: 5px;
    left: -40px;
  }
}
