/* mulish-500 - latin */
@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../fonts/mulish-v1-latin-500.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/mulish-v1-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}
/* mulish-900 - latin */
@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 900;
  src: local(""), url("../fonts/mulish-v1-latin-900.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/mulish-v1-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

/* recursive-regular - latin */
@font-face {
  font-family: "Recursive";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("../fonts/recursive-v21-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/recursive-v21-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}
/* recursive-900 - latin */
@font-face {
  font-family: "Recursive";
  font-style: normal;
  font-weight: 900;
  src: local(""), url("../fonts/recursive-v21-latin-900.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/recursive-v21-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

:root {
  --blue: #63b7e7;
  --green: #95c21e;
  --duck-blue: #0f7980;
  --dark-blue: #0f5983;
  --turquoise: #00aeb9;
  --olive-green: #769d20;
  --grey: #d9e3fd;
  --black: #000;
  --white: #fff;
  --page-background: #fff;

  --text-font-size: 16px;
  --text-line-height: 24px;

  --content-width: 895px;
  --body-width: 1370px;
  --title-width: 1300px;
  --mobile: 600px;

  --gutter: 20px;

  --font: "Recursive", sans-serif;
  --font-alt: "Mulish", sans-serif;
}

body {
  color: var(--black);
  font-family: var(--font);
  background: var(--page-background);
  font-weight: 400;
  overflow-x: hidden;
  scroll-behavior: smooth;
  --theme: var(--turquoise);
  &.theme-ve {
    --theme: var(--green);
  }

  &.theme-bl {
    --theme: var(--blue);
  }

  &.theme-tu {
    --theme: var(--turquoise);
  }
}

body a {
  text-decoration: none;
}

/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
  overflow-x: hidden;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-alt);
}

img.richtext-image {
  max-width: 100%;
  height: auto;
}
